import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { fontAwesomeIcons } from './config/font-awesome-icons';

@NgModule({
  imports: [NgxPermissionsModule.forRoot()],
  exports: [CommonModule, NgbModule, FontAwesomeModule, NgxPermissionsModule],
})
export class SharedLibsModule {
  constructor(private fontAwesomeLibrary: FaIconLibrary) {
    this.configureFontAwesome();
  }

  private configureFontAwesome() {
    // this.fontAwesomeLibrary.addIcons(...fontAwesomeIcons);
  }
}
