import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ErrorDataService } from '../error-data.service';
import { HttpCodeError } from '../http-code-error.enum';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private errorDataService: ErrorDataService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.errorDataService.storage = null;

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.errorDataService.storage = err.status;
          if (
            err.status === HttpCodeError.UNAUTHORIZED &&
            environment.production
          ) {
            window.location.href = '/login';
          } else if (
            err.status === HttpCodeError.UNKNOWN_ERROR ||
            err.status === HttpCodeError.BAD_REQUEST ||
            err.status === HttpCodeError.FORBIDDEN ||
            err.status === HttpCodeError.REQUEST_TIMEOUT ||
            err.status === HttpCodeError.INTERNAL_SERVER_ERROR ||
            err.status === HttpCodeError.BAD_GATEWAY ||
            err.status === HttpCodeError.SERVICE_UNAVAILABLE ||
            err.status === HttpCodeError.GATEWAY_TIMEOUT
          ) {
            this.router.navigateByUrl('error');
          } else if (err.status === 404) {
            this.router.navigateByUrl('not-found');
          }
        }
        return throwError(err);
      })
    );
  }
}
