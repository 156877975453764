import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  collapsed = true;

  constructor(private router: Router) {}

  public navigate(route): void {
    this.router.navigateByUrl(route);
  }
}
