import { Routes } from '@angular/router';
import { ErrorComponent } from './modules/core/handlers/error/error.component';
import { NotFoundComponent } from './modules/core/handlers/not-found/not-found.component';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/pages/pages.module').then((m) => m.PagesModule),
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: NotFoundComponent },
];
