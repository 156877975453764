import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  environment;

  constructor(private router: Router) {
    this.environment = environment;
  }

  goTo(): void {
    this.router.navigate(['/']);
  }
}
