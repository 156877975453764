<div
  class="h-100 d-flex flex-column justify-content-center align-items-center"
  [ngSwitch]="errorCode"
>
  <app-unknown
    #unknown
    *ngSwitchCase="httpCodeErrors.UNKNOWN_ERROR"
  ></app-unknown>
  <app-bad-gateway
    #badGateway
    *ngSwitchCase="httpCodeErrors.BAD_GATEWAY"
  ></app-bad-gateway>
  <app-bad-request
    #badRequest
    *ngSwitchCase="httpCodeErrors.BAD_REQUEST"
  ></app-bad-request>
  <app-forbidden
    #forbidden
    *ngSwitchCase="httpCodeErrors.FORBIDDEN"
  ></app-forbidden>
  <app-gateway-timeout
    #gatewayTimeout
    *ngSwitchCase="httpCodeErrors.GATEWAY_TIMEOUT"
  ></app-gateway-timeout>
  <app-internal-server-error
    #internalServeError
    *ngSwitchCase="httpCodeErrors.INTERNAL_SERVER_ERROR"
  ></app-internal-server-error>
  <app-request-timeout
    #requestTimeout
    *ngSwitchCase="httpCodeErrors.REQUEST_TIMEOUT"
  ></app-request-timeout>
  <app-service-unavailable
    #serviceUnavailable
    *ngSwitchCase="httpCodeErrors.SERVICE_UNAVAILABLE"
  ></app-service-unavailable>

  <button mat-raised-button color="primary" (click)="goTo()">
    Voltar Página
  </button>
</div>
