import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from './material.module';
import { NavbarComponent } from './navbar/navbar.component';
import { SharedLibsModule } from './shared-libs.module';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  declarations: [NavbarComponent, ToastComponent, FooterComponent],
  imports: [MaterialModule, SharedLibsModule],
  exports: [NavbarComponent, ToastComponent, MaterialModule, SharedLibsModule, FooterComponent],
})
export class SharedModule {}
