<ngb-toast
  [class]="toast.className"
  *ngFor="let toast of toastService.toasts"
  [autohide]="toast.delay > 0"
  [delay]="toast.delay"
  (hide)="toastService.remove(toast)"
>
  <div class="summary">
    <span [ngSwitch]="toast.level">
      <fa-icon
        class="pr-2 mr-1"
        *ngSwitchCase="'information'"
        [icon]="['fas', 'info-circle']"
      ></fa-icon>
      <fa-icon
        class="pr-2 mr-1"
        *ngSwitchCase="'success'"
        [icon]="['fas', 'check-circle']"
      ></fa-icon>
      <fa-icon
        class="pr-2 mr-1"
        *ngSwitchCase="'error'"
        [icon]="['fas', 'times-circle']"
      ></fa-icon>
      <fa-icon
        class="pr-2 mr-1"
        *ngSwitchCase="'warning'"
        [icon]="['fas', 'exclamation-circle']"
      ></fa-icon>
      <span *ngSwitchDefault></span>
    </span>
    <strong> {{ toast.summary }}</strong>
  </div>
  <div class="details">
    <div [ngbCollapse]="toast.details && !toast.showDetails">
      <strong>{{ toast.details }}</strong>
    </div>
  </div>
</ngb-toast>
