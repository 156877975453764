import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorDataService } from '../error-data.service';
import { HttpCodeError } from '../http-code-error.enum';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  errorCode;
  httpCodeErrors;

  constructor(
    private errorDataService: ErrorDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.httpCodeErrors = HttpCodeError;

    if ([null, undefined].includes(this.errorDataService.storage)) {
      this.router.navigate(['not-found']);
    } else {
      this.errorCode = this.errorDataService.storage;
    }
  }

  ngOnDestroy(): void {
    // Cleaning error code.
    this.errorDataService.storage = null;
  }

  goTo(): void {
    this.router.navigateByUrl('');
  }
}
