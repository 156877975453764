import { Component } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss'],
})
export class ServiceUnavailableComponent {
  environment;

  constructor() {
    this.environment = environment;
  }
}
