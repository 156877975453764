<div fxLayout="row wrap" class="footer w-100 h-auto px-3 py-2 py-md-3">

    <div class="container-fluid h-100 d-flex w-100 h-100 d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-wrap">
        <a id="codata" href="https://codata.pb.gov.br" target="_blank">
            <img class="codata my-2 my-md-0" alt="Logomarca da Codata" fluid src="assets/images/logo_codata.png" />
        </a>
        <a id="government" href="https://paraiba.pb.gov.br" target="_blank">
            <img class="gov pl-md-3" alt="Logomarca do Governo da Paraíba" fluid src="assets/images/logo_governo.png" />
        </a>
    </div>
</div>
