import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { NotFoundComponent } from './modules/core/handlers/not-found/not-found.component';
import { SharedModule } from './shared/shared.module';
import { ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { FlexLayoutModule } from '@angular/flex-layout';
import {NgbPaginationModule, NgbAlertModule, NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  providers: [ReCaptchaV3Service, ScriptService, NgbActiveModal],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    FlexLayoutModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  declarations: [AppComponent, NotFoundComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
