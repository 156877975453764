import { Component } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-gateway-timeout',
  templateUrl: './gateway-timeout.component.html',
  styleUrls: ['./gateway-timeout.component.scss'],
})
export class GatewayTimeoutComponent {
  environment;

  constructor() {
    this.environment = environment;
  }
}
