import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../../../shared/shared.module';
import { ErrorDataService } from './error-data.service';
import { ErrorComponent } from './error/error.component';
import {
  BadGatewayComponent,
  BadRequestComponent,
  ForbiddenComponent,
  GatewayTimeoutComponent,
  InternalServerErrorComponent,
  RequestTimeoutComponent,
  ServiceUnavailableComponent
} from './error/http';
import { UnknownComponent } from './error/http/unknown/unknown.component';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'error',
        component: ErrorComponent,
      },
    ]),
  ],
  declarations: [
    ErrorComponent,
    BadGatewayComponent,
    BadRequestComponent,
    ForbiddenComponent,
    GatewayTimeoutComponent,
    InternalServerErrorComponent,
    RequestTimeoutComponent,
    ServiceUnavailableComponent,
    UnknownComponent,
  ],
  exports: [
    ErrorComponent,
    BadGatewayComponent,
    BadRequestComponent,
    ForbiddenComponent,
    GatewayTimeoutComponent,
    InternalServerErrorComponent,
    RequestTimeoutComponent,
    ServiceUnavailableComponent,
    UnknownComponent,
  ],
  providers: [
    ErrorDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
})
export class HandlerModule {}
