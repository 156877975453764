import { Component } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-unknown',
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.scss'],
})
export class UnknownComponent {
  environment;

  constructor() {
    this.environment = environment;
  }
}
