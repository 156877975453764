import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private $toasts: any[] = [];
  private static readonly defaultDelay = 8000;

  public remove(toast) {
    this.$toasts = this.$toasts.filter((t) => t !== toast);
  }

  public primary(summary: string) {
    this.show(summary, {
      level: undefined,
      className: 'bg-primary text-white',
      delay: ToastService.defaultDelay,
    });
  }

  public secondary(summary: string) {
    this.show(summary, {
      level: 'information',
      className: 'bg-secondary text-white',
      delay: ToastService.defaultDelay,
    });
  }

  public success(summary: string) {
    this.show(summary, {
      level: 'success',
      className: 'bg-success text-white',
      delay: ToastService.defaultDelay,
    });
  }

  public danger(summary: string) {
    this.show(summary, {
      level: 'error',
      className: 'bg-danger text-white',
      delay: ToastService.defaultDelay,
    });
  }

  public warning(summary: string) {
    this.show(summary, {
      level: 'warning',
      className: 'bg-warning text-dark',
      delay: ToastService.defaultDelay,
    });
  }

  public info(summary: string) {
    this.show(summary, {
      level: 'information',
      className: 'bg-info text-white',
      delay: ToastService.defaultDelay,
    });
  }

  public light(summary: string) {
    this.show(summary, {
      level: 'information',
      className: 'bg-light text-dark',
      delay: ToastService.defaultDelay,
    });
  }

  public white(summary: string) {
    this.show(summary, {
      level: 'information',
      className: 'bg-white text-dark',
      delay: ToastService.defaultDelay,
    });
  }

  public transparent(summary: string) {
    this.show(summary, {
      level: 'information',
      className: 'bg-transparent text-dark',
      delay: ToastService.defaultDelay,
    });
  }

  public get toasts() {
    return this.$toasts;
  }

  private show(summary: string, options: any) {
    if (!environment.production) {
      // tslint:disable-next-line: no-console
      console.debug(summary, options);
    }
    this.$toasts.push({ summary, ...options });
  }
}
