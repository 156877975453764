import { Component } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-bad-request',
  templateUrl: './bad-request.component.html',
  styleUrls: ['./bad-request.component.scss'],
})
export class BadRequestComponent {
  environment;

  constructor() {
    this.environment = environment;
  }
}
